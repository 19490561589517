<template>
  <div>
    <div>
      <div class="project-search">
        <div class="flex search-text align-center">
          <div class="marginLeft10">
            <span>名称：</span>
            <input v-model="pageInfo.laboratoryName" placeholder="实验室名称" class="searchInput" />
          </div>
          <div class="marginLeft10">
            <span>学院名称：</span>
            <el-select v-model="pageInfo.collegeId" filterable placeholder="请选择一个学院" size="large">
              <el-option v-for="item in $root.collegeList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </div>
          <button class="searchBtn" @click="search">搜索</button>
          <button class="resetBtn" @click="reset(null)">重置</button>
        </div>
      </div>
      <div class="project-table" style="margin-top: 20px">
        <div class="flex align-center justify-between paddingBTM20">
          <div class="fontSize20gay">实验室整洁评分列表</div>
          <div><button class="addBtn marginLR10" @click="handleExport">导出</button></div>
        </div>
        <el-table :data="tableData" border row-key="id"
                  :header-cell-style="{'text-align':'center','color':'#999999','padding':'16px 0','background-color':'#EBEBEB'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column fixed prop="laboratoryName" label="实验室名称" width="120" />
          <el-table-column fixed prop="collegeName" label="所属学院" width="120" />
          <el-table-column fixed label="开放率" width="120">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <el-progress type="circle" :percentage="scope.row.open" width="80" color="#e6a23c" />
            </template>
          </el-table-column>
          <el-table-column fixed label="使用率" width="120">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <el-progress type="circle" :percentage="scope.row.use" width="80" color="#1989fa" />
            </template>
          </el-table-column>
          <el-table-column fixed label="巡检率" width="120">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <el-progress type="circle" :percentage="scope.row.xj" width="80" color="#5cb87a" />
            </template>
          </el-table-column>
          <el-table-column label="第1周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,1)" @click="goSetScore(sweek(scope.row.data,1))">{{sweek(scope.row.data,1).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第2周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,2)" @click="goSetScore(sweek(scope.row.data,2))">{{sweek(scope.row.data,2).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第3周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,3)"  @click="goSetScore(sweek(scope.row.data,3))">{{sweek(scope.row.data,3).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第4周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,4)"  @click="goSetScore(sweek(scope.row.data,4))">{{sweek(scope.row.data,4).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第5周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,5)"  @click="goSetScore(sweek(scope.row.data,5))">{{sweek(scope.row.data,5).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第6周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,6)"  @click="goSetScore(sweek(scope.row.data,6))">{{sweek(scope.row.data,6).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第7周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,7)"  @click="goSetScore(sweek(scope.row.data,7))">{{sweek(scope.row.data,7).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第8周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,8)"  @click="goSetScore(sweek(scope.row.data,8))">{{sweek(scope.row.data,8).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第9周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,9)"  @click="goSetScore(sweek(scope.row.data,9))">{{sweek(scope.row.data,9).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第10周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,9)"  @click="goSetScore(sweek(scope.row.data,10))">{{sweek(scope.row.data,9).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第11周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,11)" @click="goSetScore(sweek(scope.row.data,11))">{{sweek(scope.row.data,11).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第12周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,12)" @click="goSetScore(sweek(scope.row.data,12))">{{sweek(scope.row.data,12).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第13周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,13)" @click="goSetScore(sweek(scope.row.data,13))">{{sweek(scope.row.data,13).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第14周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,14)" @click="goSetScore(sweek(scope.row.data,14))">{{sweek(scope.row.data,14).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第15周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,15)" @click="goSetScore(sweek(scope.row.data,15))">{{sweek(scope.row.data,15).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第16周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,16)" @click="goSetScore(sweek(scope.row.data,16))">{{sweek(scope.row.data,16).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第17周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,17)" @click="goSetScore(sweek(scope.row.data,17))">{{sweek(scope.row.data,17).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第18周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,18)" @click="goSetScore(sweek(scope.row.data,18))">{{sweek(scope.row.data,18).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第19周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,19)" @click="goSetScore(sweek(scope.row.data,19))">{{sweek(scope.row.data,19).score}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="第20周">
            <template #default="scope">
              <div v-if="scope.row.data">
                <el-button link type="primary" v-if="sweek(scope.row.data,20)" @click="goSetScore(sweek(scope.row.data,20))">{{sweek(scope.row.data,20).score}}</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!--   分页     -->
          <el-pagination
              :currentPage="pageInfo.page"
              :page-size="pageInfo.limit"
              :page-sizes="[10, 20, 50, 100]"
              :small="true"
              :disabled="false"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <!-- 操作窗口   -->
    <el-dialog title="整洁评分信息" class="info-div600" v-model="dialogFormVisible" style="padding: 24px;text-align: left"
               :close-on-press-escape="false" :close-on-click-modal="false">
      <el-form-item label="得分" label-width="80px">
        <span>{{scoreData.score}}</span>
      </el-form-item>
      <el-form-item label="图片" label-width="80px">
        <div v-if="scoreData.img" class="flex align-start"><img v-for="m in scoreData.img" :src="projectName+m" @click="showImage(projectName+m)" style="width: 60px;margin-right: 10px" /></div>
      </el-form-item>
    </el-dialog>
    <el-dialog v-model="showImageVisible">
      <img w-full :src="showImageUrl" alt="Preview Image" style="width: 100%" />
    </el-dialog>
  </div>
</template>

<script>
import {exportFile, getRoomExamineList} from "@/api/api";
import {projectName} from "@/utils/config"
import {getToken} from "@/utils/monitor";
export default {
  name: "roomList",
  data(){
    return{
      token:localStorage.getItem('token'),
      tableData:[],
      dataInfo:[],
      projectName:projectName,
      Info:null,
      seeInfo:null,
      dialogFormVisible:false,
      showImageVisible:false,
      showImageUrl:'',
      pageInfo:{
        limit:5,
        laboratoryName:'',
        collegeId:'',
        page:1,
        total:0,
      },
      collegeList:[],
      scoreData:{},
      exportIds:''
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    reset(){
      this.pageInfo.laboratoryName='';
      this.pageInfo.collegeId=''
    },
    search(){
      this.pageInfo.limit=5;
      this.pageInfo.page=1;
      this.getList();
    },
    getList(){
      getRoomExamineList(this.pageInfo).then(res=>{
        this.pageInfo.total=parseInt(res.data.total);
        this.tableData=res.data.records;
      })
    },
    goSetScore(data){
      console.log(data);
      let imgList=[];
      if(data.img) {
        imgList = data.img.split(",");
      }
      this.scoreData={score:data.score,img:imgList};
      this.dialogFormVisible=true;
    },
    sweek(data,week){//当前周的数据
      let po='';
      data.find(res=>{
        if(res.week==week){
          po=res;
        }
      })
      return po;
    },
    seeLab(id,name){
      this.seeInfo={id:id,name:name};
      this.dialogFormVisible=true;
      this.$refs.editDiv.getInfo(this.seeInfo);//调用子组件方法
    },
    showRenew(id){
      this.dialogFormVisible1=true;
      this.$refs.renewDiv.getInfo(id);//调用子组件方法
    },
    showImage(url){
      this.showImageUrl=url;
      this.showImageVisible=true;
    },
    closeDiv(){
      this.dialogFormVisible=false;
    },
    handleExport() {
      const url = `/laboratory-examine/export`
          + `?collegeId=${this.pageInfo.collegeId}`
          + `&laboratoryName=${this.pageInfo.laboratoryName}`
          + `&Authorization=Bearer ${getToken()}`
      exportFile(url)
    },
    handleSizeChange(e){//修改每页显示条数
      this.pageInfo.limit=e;
      this.pageInfo.page=1;
      this.getList();
    },
    handleCurrentChange(e){
      this.pageInfo.page=e;
      this.getList();
    },
    showDown(name){
      downFile(name);
    },
    beforxfg(){
      this.loading = this.$root.loadingOpen();
    },
    fileVb(res){
      if(res.success){
        this.$root.ElMessage({type:'success',message:res.message});
        this.getList();
      }else{
        this.$root.ElMessage.error(res.message);
      }
      this.loading.close();
    }
  }
}
</script>

<style lang="scss" scoped>
.data-title{font-size: 15px;color: #000000}
.data-content{
  font-size: 13px;display: flex;justify-content: space-between;margin-top: 15px;
}
</style>